<template>
  <div>
    <b-col md="2">

      <b-form-group
        label="Parceiro"
        label-for="partner"
      >
        <b-form-select
          v-model="partner"
          disabled
          :options="['viainvest']"
        />
      </b-form-group>
    </b-col>
    <ViaInvestBI url="viainvest_ccb_report?PARCEIRO=viainvest" />
  </div>

</template>

<script>
import {
    BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue';
import ViaInvestBI from '../components/ViaInvest.vue';

export default {
    components: {
        ViaInvestBI, BRow, BCol, BFormGroup, BFormSelect,
    },

    data() {
        return {
            partner: 'viainvest',
        };
    },
};
</script>
