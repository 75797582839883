<template>
  <div>
    <b-embed
      type="iframe"
      :src="link"
      allowfullscreen
    />
  </div>
</template>

<script>
import { BEmbed } from 'bootstrap-vue';

export default {
    components: {
        BEmbed,
    },

    props: {
        url: {
            type: String,
            required: true,
        },
    },

    computed: {
        link() {
            return `${process.env.VUE_APP_BI}${this.url}`;
        },
    },
};
</script>
